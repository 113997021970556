import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { CButton } from '@coreui/react'
import { useFormik } from 'formik'

import * as Yup from 'yup'
import {
  addEditKeyword,
  addEditTheme,
} from 'src/api/apiHandler'
import { showErrorMessage, showPromiseNotification } from 'src/common/notification'

const KeywordModal = (props) => {
  const {
    addKeywordModal,
    setAddKeywordModal,
    initialFilter,
    setFilter,
    setPayload,
    handleKeywordList,
    data = {},
    setData,
    setKeyword,
    themeList
  } = props
  const validationSchema = Yup.object().shape({
    theme_id: Yup.string().required('Please select Theme Name'),
    name: Yup.string()
      .required('* Please Enter keyword Name')
      .min(3, '* Too Short!')
      .max(80, '* Too Long!')
      .matches(/^\S(?:\s?\S)*$/, '* Not start with white space'),
    // icon_image: Yup.mixed()
    //   .test('fileSize', 'File size is too large (max 10 MB)', (value) => {
    //     if (!value || !value.size) return true
    //     return value.size <= 10 * 1024 * 1024
    //   })
    //   .required('Please Add Icon Image'),
  })
  const addEditForm = useFormik({
    enableReinitialize: true,
    initialValues: { name: data?.data?.name || '', theme_id: data?.data?.theme_id || '' },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const handlePromise = (promise) => {
        return new Promise((resolve, reject) => {
          promise
            .then((response) => {
              if (response.code === 200) {

                if (data?.type === 'add') {
                  setFilter(initialFilter)
                  setPayload(initialFilter)
                  handleKeywordList(initialFilter)
                } else if (data?.type == 'edit') {
                  setKeyword((prevIcon) => {
                    return prevIcon.map((item) => {
                      if (item.id === data?.data?.id) {
                        item = { ...item, ...response.data }
                      }
                      return item
                    })
                  })
                }
                resolve(response)
                addEditForm.resetForm()
                setData({})
                setAddKeywordModal(false)
              } else {
                reject(response)
              }
            })
            .catch((error) => {
              reject(error)
            })
        })
      }
      const payload = {
        name: values.name,
        theme_id: values.theme_id,
      }
      const processUpload = async () => {
        const { ...restpayload } = payload
        if (data?.data?.id) {
          restpayload.id = data?.data?.id
        }
        console.log('restpayload: ', restpayload);
        return await handlePromise(
          addEditKeyword(restpayload)
        )
      }
      showPromiseNotification(processUpload())
    },
  })
  return (
    <>
      <div
        className={addKeywordModal ? 'modal fade show d-block' : 'modal fade'}
        tabIndex={-1}
        data-coreui-backdrop="static"
        data-coreui-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {data?.type === 'edit' ? 'Edit' : data?.type === 'view' ? 'View' : 'Add'} Keyword
              </h5>
              <button
                type="button"
                className="btn-close"
                data-coreui-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setData({})
                  addEditForm.resetForm()
                  setAddKeywordModal(false)
                }}
              />
            </div>
            <form onSubmit={addEditForm.handleSubmit}>
              <div className="modal-body">
                <div className="mb-3 row">
                  <div className="col-12">
                    <label htmlFor="name" className="form-label userLabel">
                      <span className="text-danger">*</span> Select Theme
                    </label>

                    <select
                      className="form-select"
                      onChange={(e) => addEditForm.handleChange(e)}
                      id="theme_id"
                      name="theme_id"
                      disabled={data?.type === 'view'}
                      value={addEditForm.values.theme_id}
                    >
                      <option value="" disabled selected>
                        Select theme
                      </option>
                      {themeList?.map((item) => {
                        return (
                          <option key={item?.id} value={item?.id}>
                            {item?.name}
                          </option>
                        )
                      })}
                    </select>

                    {addEditForm.submitCount && addEditForm.errors.theme_id ? (
                      <span className="error mt-2">{addEditForm.errors.theme_id}</span>
                    ) : null}
                  </div>
                  <div className="col-12">
                    <label htmlFor="name" className="form-label userLabel">
                      <span className="text-danger">*</span> Keyword Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      placeholder="Enter keyword name"
                      value={addEditForm.values.name}
                      disabled={data?.type === 'view'}
                      onChange={(e) => addEditForm.handleChange(e)}
                    />
                    {addEditForm.submitCount && addEditForm.errors.name ? (
                      <span className="error mt-2">{addEditForm.errors.name}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <CButton
                  color="primary"
                  onClick={() => {
                    setData({})
                    addEditForm.resetForm()
                    setAddKeywordModal(false)
                  }}
                  className="theme-custom-btn-outline"
                >
                  Close
                </CButton>
                {data?.type !== 'view' && (
                  <CButton
                    color="primary"
                    type="submit"
                    // onClick={() => {
                    //   setAddCategoryModal(false)
                    // }}
                    className="theme-custom-btn"
                  >
                    {data?.type === 'edit' ? 'Edit' : 'Add'}
                  </CButton>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      {addKeywordModal && <div className="modal-backdrop fade show"></div>}
    </>
  )
}

KeywordModal.propTypes = {
  addKeywordModal: PropTypes.bool,
  setAddKeywordModal: PropTypes.func,
  setFilter: PropTypes.func,
  setPayload: PropTypes.func,
  initialFilter: PropTypes.object,
  handleKeywordList: PropTypes.func,
  data: PropTypes.object,
  setData: PropTypes.func,
  setKeyword: PropTypes.func,
  themeList: PropTypes.array,
}

export default KeywordModal
