import React from 'react'
import PropTypes from 'prop-types'
import { CButton } from '@coreui/react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addCategory, editCategory } from 'src/api/apiHandler'
import { showPromiseNotification } from 'src/common/notification'

const AddEditCategory = (props) => {
  const {
    addCategoryModal,
    setAddCategoryModal,
    initialFilter,
    setFilter,
    setPayload,
    getCategoryList,
    data = {},
    setData,
  } = props

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Please Enter Category Name')
      .min(3, 'Too Short!')
      .max(30, 'Too Long!')
    ,// .matches(/^\S(?:\s?\S)*$/, 'Not start with white space'),
    slug: Yup.string()
      .required('Please Enter Category Slug')
      .matches(/^[^\s/]+$/, 'Slug should not contain spaces or slash (/)') // Ensures no spaces or '/'
      .min(3, 'Slug is too short')
      .max(30, 'Slug is too long')
  })

  const addEditForm = useFormik({
    enableReinitialize: true,
    initialValues: { name: data?.data?.name || '', slug: data?.data?.category_key },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (data?.data?.id) {
        const payload = {
          ...values,
          id: data?.data?.id,
        }
        const myPromise = new Promise((resolve, reject) =>
          editCategory(payload).then((response) => {
            if (response.code === 200) {
              setFilter(initialFilter)
              setPayload(initialFilter)
              getCategoryList(initialFilter)
              setAddCategoryModal(false)
              resolve(response)
            } else {
              reject(response)
            }
          }),
        )
        showPromiseNotification(myPromise)
      } else {
        const myPromise = new Promise((resolve, reject) =>
          addCategory(values).then((response) => {
            if (response.code === 200) {
              setFilter(initialFilter)
              setPayload(initialFilter)
              getCategoryList(initialFilter)
              setAddCategoryModal(false)
              resolve(response)
              addEditForm.resetForm()
            } else {
              reject(response)
            }
          }),
        )
        showPromiseNotification(myPromise)
        setData({})
      }
    },
  })

  return (
    <>
      <div
        className={addCategoryModal ? 'modal fade show d-block' : 'modal fade'}
        tabIndex={-1}
        data-coreui-backdrop="static"
        data-coreui-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {data?.type === 'edit' ? 'Edit' : data?.type === 'view' ? 'View' : 'Add'} Category
              </h5>
              <button
                type="button"
                className="btn-close"
                data-coreui-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setData({})
                  addEditForm.resetForm()
                  setAddCategoryModal(false)
                }}
              />
            </div>
            <form onSubmit={addEditForm.handleSubmit}>
              <div className="modal-body">
                <div className="mb-3 row">
                  <div className="col-12">
                    <label htmlFor="name" className="form-label userLabel">
                      <span className="text-danger">*</span> Category Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      placeholder="Enter category name"
                      value={addEditForm.values.name}
                      disabled={data?.type === 'view'}
                      onChange={(e) => addEditForm.handleChange(e)}
                    />
                    {addEditForm.submitCount && addEditForm.errors.name ? (
                      <span className="error mt-2">{addEditForm.errors.name}</span>
                    ) : null}
                  </div>
                  <div className="col-12">
                    <label htmlFor="slug" className="form-label userLabel">
                      <span className="text-danger">*</span> Category Slug
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="slug"
                      id="slug"
                      placeholder="Enter category slug"
                      value={addEditForm.values.slug}
                      disabled={data?.type === 'view'}
                      onChange={(e) => addEditForm.handleChange(e)}
                    />
                    {addEditForm.submitCount && addEditForm.errors.slug ? (
                      <span className="error mt-2">{addEditForm.errors.slug}</span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <CButton
                  color="primary"
                  onClick={() => {
                    setData({})
                    addEditForm.resetForm()
                    setAddCategoryModal(false)
                  }}
                  className="theme-custom-btn-outline"
                >
                  Close
                </CButton>
                {data?.type !== 'view' && (
                  <CButton
                    color="primary"
                    type="submit"
                    // onClick={() => {
                    //   setAddCategoryModal(false)
                    // }}
                    className="theme-custom-btn"
                  >
                    {data?.type === 'edit' ? 'Edit' : 'Add'}
                  </CButton>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      {addCategoryModal && <div className="modal-backdrop fade show"></div>}
    </>
  )
}

AddEditCategory.propTypes = {
  addCategoryModal: PropTypes.bool,
  setAddCategoryModal: PropTypes.func,
  setFilter: PropTypes.func,
  setPayload: PropTypes.func,
  initialFilter: PropTypes.object,
  getCategoryList: PropTypes.func,
  data: PropTypes.object,
  setData: PropTypes.func,
}

export default AddEditCategory
