import React from 'react'
import CategoryPage from './views/Category/Category'
import SubCategoryPage from './views/Subcategory/SubCategory'
import SubAdmin from './views/SubAdmin/SubAdmin'
import AddEditViewSubAdmin from './views/SubAdmin/AddEditViewSubAdmin'
import { getUserPermissions, hasPermissionForModule, isAdmin } from './common/LocalStorageService'
import MonthlySettlementsPage from './views/MonthlySettlement/MonthlySettlement'
import Dashboard from './views/dashboard/Dashboard'
import User from './views/User/User'
import EditUser from './views/User/EditUser'
import DesignerRequest from './views/User/DesignerRequest'
import ViewDesignerRequest from './views/User/ViewDesignerRequest'
import StaticPage from './views/staticPage/StaticPage'
import EditStaticPage from './views/staticPage/EditStaticPage'
import Contactus from './views/staticPage/Contactus'
import Profile from './views/profile/profile'
import ViewContactus from './views/staticPage/ViewEditContactus'
import SocialMediaIcon from './views/SocialMediaIcon/SocialMediaIcon'
import Shapes from './views/Shapes/shapes'
import Sticker from './views/Sticker/sticker'
import Clipart from './views/Clipart/clipart'
import Template from './views/Template/template'
import ViewTemplate from './views/Template/viewTemplate'
import RatingReview from './views/RatingReview/ratingReview'
import Notification from './views/Notification/notification'
import AddViewNotification from './views/Notification/addViewNotification'
import ViewRatingReview from './views/RatingReview/viewRatingReview'
import Subscription from './views/Subscription/Subscription'
import AddEditViewSubscription from './views/Subscription/AddSubscription'
import MyEarning from './views/MyEarning/MyEarning'
import FontPage from './views/Fonts/fonts'
import EditFaq from './views/staticPage/EditFaq'
import FAQ from './views/staticPage/FAQ'
import ViewMonthlySettlementsPage from './views/MonthlySettlement/ViewMonthlySettlement'
import ThemePage from './views/Theme/Theme'
import KeywordPage from './views/Keyword/Keyword'

const routes = [
  { path: '/dashboard', name: 'Dashboard', element: <Dashboard />, access: true },
  {
    path: '/user',
    name: 'User',
    element: <User />,
    access: isAdmin() || hasPermissionForModule('User'),
  },
  {
    path: '/user/view-user/:id',
    name: 'View User',
    element: <EditUser type="view" />,
    access: isAdmin() || (hasPermissionForModule('User') && getUserPermissions('User')?.is_view),
  },
  {
    path: '/user/edit-user/:id',
    name: 'Edit User',
    element: <EditUser type="edit" />,
    access: isAdmin() || (hasPermissionForModule('User') && getUserPermissions('User')?.is_edit),
  },
  {
    path: '/sub-admin',
    name: 'Sub Admin',
    element: <SubAdmin />,
    access: isAdmin() || hasPermissionForModule('Sub Admin'),
  },
  {
    path: '/sub-admin/add',
    name: 'Add Sub Admin',
    element: <AddEditViewSubAdmin type="add" />,
    access:
      isAdmin() || (hasPermissionForModule('Sub Admin') && getUserPermissions('Sub Admin')?.is_add),
  },
  {
    path: '/sub-admin/view/:id',
    name: 'View Sub Admin',
    element: <AddEditViewSubAdmin type="view" />,
    access:
      isAdmin() ||
      (hasPermissionForModule('Sub Admin') && getUserPermissions('Sub Admin')?.is_view),
  },
  {
    path: '/sub-admin/edit/:id',
    name: 'Edit Sub Admin',
    element: <AddEditViewSubAdmin type="edit" />,
    access:
      isAdmin() ||
      (hasPermissionForModule('Sub Admin') && getUserPermissions('Sub Admin')?.is_edit),
  },
  {
    path: '/designerrequest',
    name: 'Designer Requests',
    element: <DesignerRequest />,
    access: isAdmin() || hasPermissionForModule('Designer Requests'),
  },
  {
    path: '/designerrequest/view-request/:id',
    name: 'View Designer Request',
    element: <ViewDesignerRequest type="view" />,
    access:
      isAdmin() ||
      (hasPermissionForModule('Designer Requests') &&
        getUserPermissions('Designer Requests')?.is_view),
  },
  {
    path: '/designerrequest/edit-request/:id',
    name: 'Edit Designer Request',
    element: <ViewDesignerRequest type="edit" />,
    access:
      isAdmin() ||
      (hasPermissionForModule('Designer Requests') &&
        getUserPermissions('Designer Requests')?.is_edit),
  },
  {
    path: '/static',
    name: 'CMS Pages',
    element: <StaticPage />,
    access: isAdmin() || hasPermissionForModule('CMS Pages'),
  },
  {
    path: '/static/view-static/:id',
    name: 'View CMS Pages',
    element: <EditStaticPage type="view" />,
    access:
      isAdmin() ||
      (hasPermissionForModule('CMS Pages') && getUserPermissions('CMS Pages')?.is_view),
  },
  {
    path: '/static/edit-static/:id',
    name: 'Edit CMS Page',
    element: <EditStaticPage type="edit" />,
    access:
      isAdmin() ||
      (hasPermissionForModule('CMS Pages') && getUserPermissions('CMS Pages')?.is_edit),
  },
  {
    path: '/faqs',
    name: 'FAQ',
    element: <FAQ />,
    access: isAdmin() || hasPermissionForModule('FAQ'),
  },
  {
    path: '/faqs/edit-faq/:id',
    name: 'Edit Faq',
    element: <EditFaq type="edit" />,
    access: isAdmin() || (hasPermissionForModule('FAQ') && getUserPermissions('FAQ')?.is_edit),
  },
  {
    path: '/faqs/view-faq/:id',
    name: 'View Faq',
    element: <EditFaq type="view" />,
    access: isAdmin() || (hasPermissionForModule('FAQ') && getUserPermissions('FAQ')?.is_edit),
  },
  {
    path: '/faqs/add-faq',
    name: 'Add Faq Page',
    element: <EditFaq type="add" />,
    access: isAdmin() || (hasPermissionForModule('FAQ') && getUserPermissions('FAQ')?.is_add),
  },
  {
    path: '/contactus',
    name: 'Contact Us Queries',
    element: <Contactus />,
    access: isAdmin() || hasPermissionForModule('ContactUs Queries'),
  },
  { path: '/view-profile', name: 'View Profile', element: <Profile type="view" />, access: true },
  { path: '/edit-profile', name: 'Edit Profile', element: <Profile type="edit" />, access: true },
  {
    path: '/contactus/view-contactus/:id',
    name: 'View Contact Us Query',
    element: <ViewContactus type="view" />,
    access:
      isAdmin() ||
      (hasPermissionForModule('ContactUs Queries') &&
        getUserPermissions('ContactUs Queries')?.is_view),
  },
  {
    path: '/category',
    name: 'Category',
    element: <CategoryPage />,
    access: isAdmin() || hasPermissionForModule('Category'),
  },
  {
    path: '/sub-category',
    name: 'Sub Category',
    element: <SubCategoryPage />,
    access: isAdmin() || hasPermissionForModule('Sub Category'),
  },
  {
    path: '/themes',
    name: 'Themes',
    element: <ThemePage />,
    access: isAdmin() || hasPermissionForModule('Themes'),
  },
  {
    path: '/keywords',
    name: 'Keywords',
    element: <KeywordPage />,
    access: isAdmin() || hasPermissionForModule('Keywords'),
  },
  {
    path: '/social-media-icon',
    name: 'Social Media Icon',
    element: <SocialMediaIcon />,
    access: isAdmin() || hasPermissionForModule('Social Media Icons'),
  },
  {
    path: '/shapes',
    name: 'Shapes',
    element: <Shapes />,
    access: isAdmin() || hasPermissionForModule('Shapes'),
  },
  {
    path: '/sticker',
    name: 'Sticker',
    element: <Sticker />,
    access: isAdmin() || hasPermissionForModule('Stickers'),
  },
  {
    path: '/clipart',
    name: 'Clipart',
    element: <Clipart />,
    access: isAdmin() || hasPermissionForModule('Cliparts'),
  },
  {
    path: '/template',
    name: 'Template',
    element: <Template />,
    access: isAdmin() || hasPermissionForModule('Templates'),
  },
  {
    path: '/view-template/:id',
    name: 'View Template',
    element: <ViewTemplate type="view" />,
    access:
      isAdmin() ||
      (hasPermissionForModule('Templates') && getUserPermissions('Templates')?.is_view),
  },
  {
    path: '/rating-review',
    name: 'Rating Review',
    element: <RatingReview />,
    access: isAdmin() || hasPermissionForModule('Rating Reviews'),
  },
  {
    path: '/fonts',
    name: 'Fonts',
    element: <FontPage />,
    access: isAdmin() || hasPermissionForModule('Fonts'),
  },
  {
    path: '/notification',
    name: 'Notification',
    element: <Notification />,
    access: isAdmin() || hasPermissionForModule('Custom Notifications'),
  },
  {
    path: '/send-notification',
    name: 'Send Notification',
    element: <AddViewNotification type="add" />,
    access:
      isAdmin() ||
      (hasPermissionForModule('Custom Notifications') &&
        getUserPermissions('Custom Notifications')?.is_add),
  },
  {
    path: '/send-notification/:id',
    name: 'Send Notification',
    element: <AddViewNotification type="view" />,
    access:
      isAdmin() ||
      (hasPermissionForModule('Custom Notifications') &&
        getUserPermissions('Custom Notifications')?.is_view),
  },
  {
    path: '/rating-review/view/:id',
    name: 'View Rating Review',
    element: <ViewRatingReview type="view" />,
    access:
      isAdmin() ||
      (hasPermissionForModule('Rating Reviews') && getUserPermissions('Rating Reviews')?.is_view),
  },
  {
    path: '/subscription',
    name: 'Subscription',
    element: <Subscription />,
    access: isAdmin() || hasPermissionForModule('Subscription'),
  },
  {
    path: '/subscription/edit-subscription/:id',
    name: 'Edit Subscription',
    element: <AddEditViewSubscription type="edit" />,
    access:
      isAdmin() ||
      (hasPermissionForModule('Subscription') && getUserPermissions('Subscription')?.is_view),
  },
  {
    path: '/subscription/view-subscription/:id',
    name: 'View Subscription',
    element: <AddEditViewSubscription type="view" />,
    access:
      isAdmin() ||
      (hasPermissionForModule('Subscription') && getUserPermissions('Subscription')?.is_view),
  },
  {
    path: '/my-earning',
    name: 'My Earning',
    element: <MyEarning />,
    access: isAdmin() || hasPermissionForModule('My Earning'),
  },
  {
    path: '/monthly-settlement',
    name: 'Monthly Settlement',
    element: <MonthlySettlementsPage />,
    access: isAdmin() || hasPermissionForModule('Monthly Settlement'),
  },
  {
    path: '/view-monthly-settlement/:id',
    name: 'View Monthly Settlement',
    element: <ViewMonthlySettlementsPage />,
    access:
      isAdmin() ||
      (hasPermissionForModule('Monthly Settlement') &&
        getUserPermissions('Monthly Settlement')?.is_view),

    // access: true
  },
]

export default routes
