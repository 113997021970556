import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { CButton } from '@coreui/react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { addCategory, editCategory } from 'src/api/apiHandler'
import { showErrorMessage, showPromiseNotification } from 'src/common/notification'
import uploadToS3 from 'src/common/s3AwsService'

const AddEditSubCategory = (props) => {
  const {
    addCategoryModal,
    setAddCategoryModal,
    initialFilter,
    setFilter,
    setPayload,
    getCategoryList,
    setCategoryList,
    getSubCategoryList,
    data = {},
    setData,
    mainCategory,
  } = props
  const fileRef = useRef()
  const iconRef = useRef()
  const [bannerImage, setBannerImage] = useState(null)
  const [iconImage, setIconImage] = useState(null)
  const [isImgChange, setIsImgChange] = useState(false)
  const [isIconImgChange, setIsIconImgChange] = useState(false)
  console.log('data132125: ', isImgChange, data);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Please Enter Category Name')
      .min(3, 'Too Short!')
      .max(30, 'Too Long!')
    ,// .matches(/^\S(?:\s?\S)*$/, 'Not start with white space'),
    slug: Yup.string()
      .required('Please Enter Category Slug')
      .matches(/^[^\s/]+$/, 'Slug should not contain spaces or slash (/)') // Ensures no spaces or '/'
      .min(3, 'Slug is too short')
      .max(30, 'Slug is too long'),
    banner_image: Yup.mixed()
      .test('fileSize', 'File size is too large (max 10 MB)', (value) => {
        if (!value || !value.size) return true
        return value.size <= 10 * 1024 * 1024
      })
      .required('Please Add Banner Image'),
    icon_image: Yup.mixed()
      .test('fileSize', 'File size is too large (max 10 MB)', (value) => {
        if (!value || !value.size) return true
        return value.size <= 10 * 1024 * 1024
      })
      .required('Please Add Icon Image'),
    category_id: Yup.string().required('Please select Category Name'),
    height: Yup.string().required('Please Enter Template Height'),
    width: Yup.string().required('Please Enter Template Width'),
  })

  const addEditForm = useFormik({
    enableReinitialize: true,
    initialValues: { name: data?.data?.name || '', category_id: data?.data?.category_id || '', banner_image: data?.data?.banner_image || '', height: data?.data?.height || '', width: data?.data?.width || '', icon_image: data?.data?.icon_image || '', slug: data?.data?.category_key },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // if (data?.data?.id) {
      //   const payload = {
      //     ...values,
      //     id: data?.data?.id,
      //   }
      //   const myPromise = new Promise((resolve, reject) =>
      //     editCategory(payload).then((response) => {
      //       if (response.code === 200) {
      //         setFilter(initialFilter)
      //         setPayload(initialFilter)
      //         getCategoryList(initialFilter)
      //         setData({})
      //         setAddCategoryModal(false)
      //         resolve(response)
      //       } else {
      //         reject(response)
      //       }
      //     }),
      //   )
      //   showPromiseNotification(myPromise)
      // } else {
      //   const myPromise = new Promise((resolve, reject) =>
      //     addCategory(values).then((response) => {
      //       if (response.code === 200) {
      //         setFilter(initialFilter)
      //         setPayload(initialFilter)
      //         getCategoryList(initialFilter)
      //         setData({})
      //         setAddCategoryModal(false)
      //         addEditForm.resetForm()
      //         resolve(response)
      //       } else {
      //         reject(response)
      //       }
      //     }),
      //   )
      //   showPromiseNotification(myPromise)
      // }
      // setData({})



      const handlePromise = (promise) => {
        return new Promise((resolve, reject) => {
          promise
            .then((response) => {
              if (response.code === 200) {
                console.log('response: ', response)

                if (data?.type === 'add') {
                  setFilter(initialFilter)
                  setPayload(initialFilter)
                  getSubCategoryList(initialFilter)
                } else if (data?.type == 'edit') {
                  // setIcon(prevIcons => {
                  //     const updatedIcons = [...prevIcons];
                  //     const indexToUpdate = updatedIcons.findIndex(icon => icon.id === data?.data?.id);
                  //     if (indexToUpdate !== -1) {
                  //         updatedIcons[indexToUpdate] = response.data;
                  //     }
                  //     return updatedIcons;
                  // });
                  setCategoryList((prevIcon) => {
                    return prevIcon.map((item) => {
                      if (item.id === data?.data?.id) {
                        item = { ...item, ...response.data }
                      }
                      return item
                    })
                  })
                }
                fileRef.current.value = '';
                iconRef.current.value = '';
                resolve(response)
                addEditForm.setFieldValue('banner_image', null)
                addEditForm.resetForm()
                setData({})
                setIsImgChange(false)
                setIsIconImgChange(false)
                setBannerImage(null)
                setAddCategoryModal(false)
              } else {
                reject(response)
              }
            })
            .catch((error) => {
              reject(error)
            })
        })
      }
      // const payload = {
      //   name: values.name,
      // }
      let payload = values
      if (data?.data?.id) {
        payload = {
          ...values,
          id: data?.data?.id,
        }
        console.log('payload: ', payload);
      }
      const processUpload = async () => {
        if (isImgChange) {
          const response = await uploadToS3(values.banner_image, 'subcategory')
          payload.image = response.key
          if (isIconImgChange) {
            const response = await uploadToS3(values.icon_image, 'subcategory')
            payload.icon_image = response.key
          }
          if (data?.data?.id) {
            payload.id = data?.data?.id
          }
          console.log('payload: ', payload);
          return await handlePromise(
            data?.data?.id ? editCategory(payload) : addCategory(payload),
          )
        } else if (isIconImgChange) {
          const response = await uploadToS3(values.icon_image, 'subcategory')
          payload.icon_image = response.key
          if (isImgChange) {
            const response = await uploadToS3(values.banner_image, 'subcategory')
            payload.image = response.key
          }
          if (data?.data?.id) {
            payload.id = data?.data?.id
          }
          console.log('payload: ', payload);
          return await handlePromise(
            data?.data?.id ? editCategory(payload) : addCategory(payload),
          )
        } else {
          const { image, ...restpayload } = payload
          if (data?.data?.id) {
            restpayload.id = data?.data?.id
          }
          restpayload.icon_image = ""
          console.log('payload12: ', restpayload);
          return await handlePromise(
            data?.data?.id
              ? editCategory(restpayload)
              : addCategory(restpayload),
          )
        }
      }
      showPromiseNotification(processUpload())
    },
  })
  const handleImageChange = useCallback(
    (e) => {
      const file = e.target.files[0]
      console.log('file: ', file)
      if (file && file.type.startsWith('image')) {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          console.log('img.width: ', img.height, img.width);
          if (img.width * (0.25) >= img.height) {
            const reader = new FileReader()
            reader.onloadend = () => {
              setBannerImage(reader?.result)
            }
            reader.readAsDataURL(file)
            setIsImgChange(true)
            setBannerImage(file)
            addEditForm.setFieldValue('banner_image', file)
          } else {
            showErrorMessage("Please select a landscape image( ration should be 4/1).");
          }
        };
      } else if (file) {
        setIsImgChange(false)
        setBannerImage(null)
        showErrorMessage('Only image files are allowed.')
        addEditForm.setFieldError('banner_image', 'Only image files are allowed.')
        addEditForm.setFieldValue('banner_image', null)
      } else {
        if (data?.type !== 'edit') {
          setIsImgChange(false)
          addEditForm.setFieldError('banner_image', 'Please Add Banner Image')
          addEditForm.setFieldValue('banner_image', null)
        }
      }
    },
    [addEditForm, data?.type],
  )
  const handleIconImageChange = useCallback(
    (e) => {
      const file = e.target.files[0]
      console.log('file: ', file)
      if (file && file.type.startsWith('image')) {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          console.log('img.width: ', img.height, img.width);

          const reader = new FileReader()
          reader.onloadend = () => {
            setIconImage(reader?.result)
          }
          reader.readAsDataURL(file)
          setIsIconImgChange(true)
          setIconImage(file)
          addEditForm.setFieldValue('icon_image', file)
        };
      } else if (file) {
        setIsIconImgChange(false)
        setIconImage(null)
        showErrorMessage('Only image files are allowed.')
        addEditForm.setFieldError('icon_image', 'Only image files are allowed.')
        addEditForm.setFieldValue('icon_image', null)
      } else {
        if (data?.type !== 'edit') {
          setIsIconImgChange(false)
          addEditForm.setFieldError('icon_image', 'Please Add Icon Image')
          addEditForm.setFieldValue('icon_image', null)
        }
      }
    },
    [addEditForm, data?.type],
  )
  useEffect(() => {
    if (data?.data?.banner_image) {
      setBannerImage(data?.data?.banner_image)
    }
    if (data?.data?.icon_image) {
      setIconImage(data?.data?.icon_image)
    }
  }, [data?.data?.banner_image, data?.data?.icon_image])
  console.log('addEditForm.errors: ', addEditForm.errors);

  return (
    <>
      <div
        className={addCategoryModal ? 'modal fade show d-block' : 'modal fade'}
        tabIndex={-1}
        data-coreui-backdrop="static"
        data-coreui-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {data?.type === 'edit' ? 'Edit' : data?.type === 'view' ? 'View' : 'Add'} Subcategory
              </h5>
              <button
                type="button"
                className="btn-close"
                data-coreui-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setData({})
                  addEditForm.resetForm()
                  setIsImgChange(false)
                  setIsIconImgChange(false)
                  fileRef.current.value = '';
                  iconRef.current.value = '';
                  setAddCategoryModal(false)
                }}
              />
            </div>
            <form onSubmit={addEditForm.handleSubmit}>
              <div className="modal-body">
                <div className="mb-3 row">
                  <input
                    className="img-input d-none"
                    id="file"
                    // {...register('profile_image')}
                    name="banner_image"
                    accept="image/*"
                    type="file"
                    // style={{ height: 'fit-content' }}
                    onChange={(e) => handleImageChange(e)}
                    ref={fileRef}
                    disabled={data?.type == 'view'}
                  />
                  <input
                    className="img-input d-none"
                    id="iconfile"
                    // {...register('profile_image')}
                    name="icon_image"
                    accept="image/*"
                    type="file"
                    // style={{ height: 'fit-content' }}
                    onChange={(e) => handleIconImageChange(e)}
                    ref={iconRef}
                    disabled={data?.type == 'view'}
                  />
                  <div className="col-12">
                    <label htmlFor="name" className="form-label userLabel">
                      <span className="text-danger">*</span> Subcategory Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      placeholder="Enter subcategory name"
                      value={addEditForm.values.name}
                      disabled={data?.type === 'view'}
                      onChange={(e) => addEditForm.handleChange(e)}
                    />
                    {addEditForm.submitCount && addEditForm.errors.name ? (
                      <span className="error mt-2">{addEditForm.errors.name}</span>
                    ) : null}
                  </div>
                  <div className="col-12">
                    <label htmlFor="name" className="form-label userLabel">
                      <span className="text-danger">*</span> Select Category
                    </label>

                    <select
                      className="form-select"
                      onChange={(e) => addEditForm.handleChange(e)}
                      id="category_id"
                      name="category_id"
                      disabled={data?.type === 'view'}
                      value={addEditForm.values.category_id}
                    >
                      <option value="" disabled>
                        Select category
                      </option>
                      {mainCategory?.map((item) => {
                        return (
                          <option key={item?.id} value={item?.id}>
                            {item?.name}
                          </option>
                        )
                      })}
                    </select>

                    {addEditForm.submitCount && addEditForm.errors.name ? (
                      <span className="error mt-2">{addEditForm.errors.name}</span>
                    ) : null}
                  </div>
                  <div className="col-12">
                    <label htmlFor="slug" className="form-label userLabel">
                      <span className="text-danger">*</span> Category Slug
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="slug"
                      id="slug"
                      placeholder="Enter category slug"
                      value={addEditForm.values.slug}
                      disabled={data?.type === 'view'}
                      onChange={(e) => addEditForm.handleChange(e)}
                    />
                    {addEditForm.submitCount && addEditForm.errors.slug ? (
                      <span className="error mt-2">{addEditForm.errors.slug}</span>
                    ) : null}
                  </div>
                  <div className="col-12">
                    <label htmlFor="height" className="form-label userLabel">
                      <span className="text-danger">*</span> Template Height
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="height"
                      id="height"
                      placeholder="Enter Template Height"
                      value={addEditForm.values.height}
                      disabled={data?.type === 'view'}
                      onChange={(e) => addEditForm.handleChange(e)}
                    />
                    {addEditForm.submitCount && addEditForm.errors.height ? (
                      <span className="error mt-2">{addEditForm.errors.height}</span>
                    ) : null}
                  </div>
                  <div className="col-12">
                    <label htmlFor="width" className="form-label userLabel">
                      <span className="text-danger">*</span> Template Width
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="width"
                      id="width"
                      placeholder="Enter Template Width"
                      value={addEditForm.values.width}
                      disabled={data?.type === 'view'}
                      onChange={(e) => addEditForm.handleChange(e)}
                    />
                    {addEditForm.submitCount && addEditForm.errors.width ? (
                      <span className="error mt-2">{addEditForm.errors.width}</span>
                    ) : null}
                  </div>
                  <div className="col-12">
                    <label htmlFor="width" className="form-label userLabel">
                      <span className="text-danger">*</span> Icon Image
                    </label>
                    <div
                      className="area"
                      style={{ display: (isIconImgChange || data?.data?.banner_image) && 'none' }}
                      onClick={() => {
                        iconRef.current.click()
                      }}
                    >
                      <div id="dropZone">Add icon here</div>
                    </div>
                    <div
                      className="icon-profile mt-3 col-12"
                      style={{ display: !isIconImgChange && !data?.data?.banner_image && 'none' }}
                    >
                      <label
                        className={data?.type === 'edit' ? '-label custom-hover' : '-label'}
                        htmlFor="file"
                      >
                        <i className="fas fa-camera"></i>
                        <span>Change Image</span>
                      </label>
                      {/* <input
                                            className="img-input"
                                            id="file"
                                            // {...register('profile_image')}
                                            name='banner_image'
                                            accept="image/*"
                                            type="file"
                                            onChange={(e) => handleImageChange(e)}
                                            // disabled={data?.type !== 'edit'}
                                            /> */}
                      <img
                        src={iconImage}
                        // src={defaultImage}
                        id="output"
                        alt="Profile"
                        className="img-thumbnail icon-image"
                        onClick={() => {
                          iconRef.current.click()
                        }}
                      />
                    </div>
                    {addEditForm.errors.icon_image ? (
                      <span className="error mt-2">{addEditForm.errors.icon_image}</span>
                    ) : null}
                  </div>
                  <div className="col-12">
                    <label htmlFor="width" className="form-label userLabel">
                      <span className="text-danger">*</span> Banner Image
                    </label>
                    <div
                      className="area"
                      style={{ display: (isImgChange || data?.data?.banner_image) && 'none' }}
                      onClick={() => {
                        fileRef.current.click()
                      }}
                    >
                      <div id="dropZone">Add banner here</div>
                    </div>
                    <div
                      className="icon-profile mt-3 col-12"
                      style={{ display: !isImgChange && !data?.data?.banner_image && 'none' }}
                    >
                      <label
                        className={data?.type === 'edit' ? '-label custom-hover' : '-label'}
                        htmlFor="file"
                      >
                        <i className="fas fa-camera"></i>
                        <span>Change Image</span>
                      </label>
                      {/* <input
                                            className="img-input"
                                            id="file"
                                            // {...register('profile_image')}
                                            name='banner_image'
                                            accept="image/*"
                                            type="file"
                                            onChange={(e) => handleImageChange(e)}
                                            // disabled={data?.type !== 'edit'}
                                            /> */}
                      <img
                        src={bannerImage}
                        // src={defaultImage}
                        id="output"
                        alt="Profile"
                        className="img-thumbnail icon-image"
                        style={{ width: "100%" }}
                        onClick={() => {
                          fileRef.current.click()
                        }}
                      />
                    </div>
                    {addEditForm.submitCount && addEditForm.errors.banner_image ? (
                      <span className="error mt-2">{addEditForm.errors.banner_image}</span>
                    ) : null}
                    <p className='mt-2 mb-0'>Ratio Should Be: 4:1 (Width x Height)</p>
                    <p className='mb-0'> Example: 1000 x 250 (Width x Height)</p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <CButton
                  color="primary"
                  onClick={() => {
                    setData({})
                    addEditForm.resetForm()
                    // addEditForm.setFieldValue('height', null)
                    setAddCategoryModal(false)
                    setBannerImage(null)
                    setIsImgChange(false)
                    setIsIconImgChange(false)
                    fileRef.current.value = '';
                    iconRef.current.value = '';
                  }}
                  className="theme-custom-btn-outline"
                >
                  Close
                </CButton>
                {data?.type !== 'view' && (
                  <CButton
                    color="primary"
                    type="submit"
                    // onClick={() => {
                    //   setAddCategoryModal(false)
                    // }}
                    className="theme-custom-btn"
                  >
                    {data?.type === 'edit' ? 'Edit' : 'Add'}
                  </CButton>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      {addCategoryModal && <div className="modal-backdrop fade show"></div>}
    </>
  )
}

AddEditSubCategory.propTypes = {
  addCategoryModal: PropTypes.bool,
  setAddCategoryModal: PropTypes.func,
  setFilter: PropTypes.func,
  setPayload: PropTypes.func,
  initialFilter: PropTypes.object,
  getCategoryList: PropTypes.func,
  data: PropTypes.object,
  setData: PropTypes.func,
  getSubCategoryList: PropTypes.func,
  setCategoryList: PropTypes.func,
  mainCategory: PropTypes.object,
}

export default AddEditSubCategory
